export const productsData = [
  {
    id: 1,
    imageSrc: require('../Images/products/product1.jpeg'),
    color: 'Бежевий',
    price: 815,
    todayPrice: 489,
    discount: 40,
    quantity:1
  },
  {
    id: 2,
    imageSrc: require('../Images/products/product2.jpeg'),
    color: 'Чорний',
    price: 815,
    todayPrice: 489,
    discount: 40,
    quantity:1
  },
  {
    id: 3,
    imageSrc: require('../Images/products/product3.jpeg'),
    color: 'Молочний',
    price: 815,
    todayPrice: 489,
    discount: 40,
    quantity:1
  },
  {
    id: 4,
    imageSrc: require('../Images/products/product4.jpeg'),
    color: 'Гірчичний',
    price: 815,
    todayPrice: 489,
    discount: 40,
    quantity:1
  },
  
];
