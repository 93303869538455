export const reviewsData = [
  {
    id: 1,
    imageSrc: require('../Images/reviews/reviews1.png'),
  },
  {
    id: 2,
    imageSrc: require('../Images/reviews/reviews2.png'),
  },
  {
    id: 3,
    imageSrc: require('../Images/reviews/reviews3.png'),
  },
  {
    id: 4,
    imageSrc: require('../Images/reviews/reviews4.png'),
  },
  {
    id: 5,
    imageSrc: require('../Images/reviews/reviews5.png'),
  },
];
