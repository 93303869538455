export const questionsData = [
  {
    id: 1,
    title: 'Як оформити замовлення',
    text: 'Виберіть жилет вище на сторінці, натисніть "обрати". Після цього відкриється вікно в якому потрібно заповнити дані для оформлення посилки. Вкажіть дані одержувача, населений пункт та номер відділення Нової пошти або індекс Укрпошти, виберіть зручний спосіб оплати. Якщо запитань чи уточнень у Вас не залишилося, можна поставити галочку ✅ для відправки посилки без дзвінка менеджера. Ми отримаємо Ваші дані та відправимо протягом 24 годин👌. Якщо у Вас залишилися питання або бажаєте оформити замовлення за допомогою менеджера, скористайтесь способом "Швидке замовлення". Залишіть Ваші дані і наш менеджер зателефонує або напише в месенджер по вашому номеру, щоб допомогти Вам😉',
  },
  {
    id: 2,
    title: 'Чи підійде жилет на розмір 40 (хs) ?',
    text: 'Окрім того що даний фасон та параметри універсальні в підборі розмір так і сучасна мода передбачає створення вільних, легких образів у відповідності кежуальній простоті та оверсайзному силуету. Тому не просто підійде а й стильно й трендово виглядатиме і дівчинка з останнього відгуку це підтверджує. Якщо сумніваєтеся в розмірі, залиште Ваші дані для зворотного дзвінка і наш менеджер Вам допоможе😊',
  },
  {
    id: 3,
    title: 'Чи підійде жилет на розмір 48 ?',
    text: 'Тканина жилету має гарні еластичні та пружні властивості, тому якщо Ваш ОГ близько 106 см тканина легко потянеться на ці додаткові 6-8 см по окружності не втрачаючи форму та якості. Довжина при цьому звісно трохи скрадеться але виглядатиме органічно при зрості до 168 см. Якщо сумніваєтеся в розмірі, залиште Ваші дані для зворотного дзвінка і наш менеджер Вам допоможе😊',
  },
  {
    id: 4,
    title: 'Як ми працюємо',
    text: 'Оформляєте заявку на сайті обравши товар або за допомогою способу швидке замовлення. Наш менеджер передзвонить Вам для уточнення деталей або напише в месенджер по Вашому номеру. **Якщо Ви заповнили всі дані одержувача в полях форми і у Вас не залишилося питань чи уточнень можна поставити галочку ✅ для відправки посилки без дзвінка менеджера. Доставимо Ваш товар протягом 1-3 днів. Отримуєте Ваш товар у відділенні Нової пошти або Укрпошти, оплачуєте посилку післяплатою або оплачуєте тільки доставку, якщо оплата за посилку вже була здійснена на картку.',
  },
  {
    id: 5,
    title: 'Оплата',
    text: 'Оплату можна зробити при отриманні посилки у відділенні Нової пошти або Укрпошти (накладний платіж). Або за передоплатою на картку (номером картки отримаєте в смс, для підтвердження потрібно точний час оплати). Фіксальний чек можна отримати по посиланню.',
  },
  {
    id: 6,
    title: 'Знижки',
    text: 'Замовте 2 та отримайте знижку 70 грн. Замовте 3 та отримайте знижку 140 грн. Замовте 4 та отримайте 5-у в подарунок або знижку 210 грн',
  },
  {
    id: 7,
    title: 'Політика повернень та обмінів',
    text: 'Ви можете повернути або поміняти блузу протягом 14 днів. При поверненні, вартість відшкодуємо Вам на картку. З появою питань завжди можете зателефонувати до нашого менеджера за телефоном 0997181662. Обмін можна здійснити на інший товар котрий запропонує менеджер. Доставку сплачує магазин коли обмін здійснюється з вени магазину.',
  },
];
