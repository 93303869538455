import './WhatToWear.css'

export const WhatToWear = () => {
  return (
<section className='whatToWhear-section'>
    <h2>З чим вдягати жилет:</h2>
    <ul className='whatToWhear-list'>
        <li className='whatToWhear-item'>
            <img className='whatToWhear-img' src={require('../../Images/whatToWhear1.jpg')} alt="" />
          
            <p className='whatToWhear-textSp'>На футболку:</p> 
            <p className='whatToWhear-text'>
            Чудовий вибір для повсякденного носіння. Вона створює легкий і невимушений образ
            </p>
        </li>
        <li className='whatToWhear-item'>
            <img className='whatToWhear-img' src={require('../../Images/whatToWhear2.jpg')}  alt="" />
            
            <p className='whatToWhear-textSp'>На топ:</p>
            <p className='whatToWhear-text'>
            Трендовий варіант, який створює дуже модний і трохи зухвалий образ. Підійде для вечірок або літніх прогулянок
            </p>
        </li>
        <li className='whatToWhear-item'>
            <img className='whatToWhear-img' src={require('../../Images/whatToWhear3.jpg')}  alt="" />
            
            <p className='whatToWhear-textSp'> На сорочку:</p>
            <p className='whatToWhear-text'>
Класичний вибір, який надає образу елегантності. Ідеально підходить для роботи або офіційних заходів
            </p>
        </li>
        <li className='whatToWhear-item'>
            <img className='whatToWhear-img' src={require('../../Images/whatToWhear4.jpg')}  alt="" />
            
                <p className='whatToWhear-textSp'> На гольф:</p>
                <p className='whatToWhear-text'>
Ідеальний варіант для холодної погоди. Носіть в'язану жилетку поверх водолазки для додаткового тепла і стилю</p>
        </li>
    </ul>
    </section>
  );
};
